.content {
  width: 100%;

  //&__last {
  //  margin-bottom: 47px;
  //}
}

.button {
  padding: 11px 34px;

  &__small {
    padding: 9px 45px;
    font-size: 16px;

    &__right {
      flex-direction: row-reverse;
      background-color: rgba($color: #000000, $alpha: 0.2);
      padding: 8px 12px;

      margin-left: 16px;
    }

    .icon {
      margin-left: 10px;
    }
  }
}

.modal {
  width: 680px;
}

.title {
  //font-size: 23px;
  font-family: "Manrope-SemiBold";
}

.smallDescription {
  font-family: "Manrope-SemiBold";
  font-size: 14px;
  color: rgba($color: #000000, $alpha: 0.5);

  margin: 12px 0;
}

.tabs {
  margin-bottom: 34px;

  &__lessMargin {
    margin-bottom: 19px;
  }
}
