.container {
  padding: 40px 20px 30vh;
  @media (min-width: 960px) {
    padding: 0 0 0;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  color: var(--color-white);
  font-size: 40px;
  font-family: "Manrope-SemiBold";

  margin-bottom: 32px;
  @media (min-width: 960px) {
    margin-bottom: 28px;
  }
}

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 100px;

  @media (min-width: 960px) {
    margin-top: 44px;
    justify-content: space-between;
    padding-bottom: unset;
  }
}

.column {
  display: flex;
  flex: 1;
  color: var(--color-white);
  line-height: 160%;
  font-family: "Manrope-Light";
  font-size: 20px;
  @media (min-width: 960px) {
    font-size: 24px;
    &:last-child {
      margin-bottom: 60px;
    }
  }
}

.point {
  display: flex;
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  font-family: "Manrope-Medium";
  font-size: 20px;
  @media (min-width: 960px) {
    font-size: 24px;
  }
}

.identifiers {
  display: flex;
  margin-top: 120px;
  margin-bottom: 120px;
  gap: 40px;
  flex-direction: column;
  @media (min-width: 960px) {
    margin-bottom: 0;
    flex-direction: row;
    gap: 80px;
  }
}

.identifier {
  color: var(--color-white);
  white-space: pre-wrap;
  line-height: 220%;
}

.dropdownRow {
  color: var(--color-white);
  display: flex;
  gap: 20px;
  font-size: 20px;
  font-family: "Manrope-Medium";
  align-items: center;
  margin-bottom: 16px;
}
