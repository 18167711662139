.container {
  position: relative;
  width: 100%;

  padding: 26px 24px 220px;

  @media (min-width: 960px) {
    padding: 0;
  }
}

.title {
  font-family: "Manrope-SemiBold";
  color: var(--color-white);
  font-size: 40px;
  line-height: 130%;
  padding-bottom: 12px;
  height: 116px;

  @media (min-width: 960px) {
    height: unset;
    font-size: 67.5px;
    padding-bottom: 33.5px;
  }
}

.inputContainer {
  width: 100%;
  @media (min-width: 960px) {
    width: 338px;
    margin-bottom: 48px;
  }
}

.input {
  margin-bottom: 24px;
  border-radius: 0;

  @media (min-width: 960px) {
    margin-bottom: 26px;
  }

  & + span {
    display: block;
    margin-top: -24px;
    margin-bottom: 24px;
  }
}

.warning {
  font-family: "Manrope-Regular";
  font-size: 10px;
  color: var(--color-white);
  line-height: 120%;
  margin-bottom: 26px;
  white-space: pre-wrap;
  cursor: pointer;

  @media (min-width: 960px) {
    font-size: 12px;
    margin-bottom: 17px;
  }
}

.button {
  font-size: 16px;
  padding: 8.5px 36.5px;

  @media (min-width: 960px) {
    font-size: 15px;
    padding: 8.75px 39.25px;
  }
}

.error {
  color: var(--color-errorRed);
}
