.button {
  z-index: 2;
  font-size: 16px;
  padding: 20px 90px;
  width: 100%;

  margin-top: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.terms {
  font-family: "Manrope-Regular";
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.5);
  text-decoration: underline;
  
  margin-top: 12px;
  cursor: pointer;

  &__input {
    cursor: default;
  }
}

.error {
  color: var(--color-errorRed);
}
