.container {
  position: fixed;
  z-index: 5;
  top: 53px;
  right: 80px;
  gap: 12px;
  display: flex;

  @media (max-width: 960px) {
    position: absolute;
    right: 20px;
    top: auto;
  }
}
