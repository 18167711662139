.outterContainer {
  position: relative;

  @media (min-width: 960px) {
  }

  .container {
    border-radius: 10px;
    border: 1px solid var(--color-white);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 8px 14px 8px 12px;

    font-family: "Manrope-Medium";
    font-size: 12px;
    line-height: 12px;
    color: var(--color-white);

    @media (min-width: 960px) {
      border: 1px solid var(--color-white);
      border-radius: 12px;
      background: var(--color-white);
      min-width: 220px;
      cursor: pointer;

      font-family: "Manrope-Medium";
      color: rgba($color: #000000, $alpha: 0.5);
    }

    &__active {
      background: var(--color-white);
      color: rgba($color: #000000, $alpha: 0.5);
    }

    &__inputField {
      font-family: "Manrope-Regular";
      font-style: normal;
      line-height: 120%;

      padding: 16px 0;
      opacity: 0.5;
      margin-bottom: 8px;
      border: 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
      border-radius: 0;
      background-color: transparent;

      color: var(--color-black);
      font-size: 20px;
    }

    &__inputFieldForm {
      font-family: "Manrope-Regular";
      font-style: normal;
      line-height: 120%;

      padding: 0;
      height: 25.5px;
      border: 0;
      border-bottom: 1.5px solid var(--color-borderGray);
      border-radius: 0;
      cursor: pointer;
      background-color: transparent;

      color: var(--color-white);
      font-size: 14px;
    }

    &__error {
      color: var(--color-errorRed);
      border-color: var(--color-errorRed);
    }

    .arrow {
      display: none;

      @media (min-width: 960px) {
        display: block;
        margin-left: auto;
      }

      &__inputField {
        display: block;
        margin-left: auto;
      }

      &__inputFieldForm {
        width: 20px;
        display: block;
        margin-left: auto;
      }
    }
  }
}

.dropdownContainer {
  border-radius: 10px;
  width: 100%;
  overflow-y: auto;
  max-height: 120px;

  position: absolute;
  z-index: 5;
  top: 37px;
  border: 1px solid var(--color-borderGray);

  background: var(--color-white);

  @-moz-document url-prefix() {
    scrollbar-color: #adb2b8 transparent;
    scrollbar-width: auto;
  }

  &__inputField {
    top: 70px;
  }

  .listItem {
    width: 100%;
    padding: 6px 12px;

    font-family: "Manrope-Medium";
    font-size: 16px;
    color: rgba($color: #000000, $alpha: 0.5);
    white-space: pre-wrap;

    cursor: default;

    @media (min-width: 960px) {
      color: rgba($color: #000000, $alpha: 0.5);
    }

    &:hover {
      background-color: rgba($color: #d9d9d9, $alpha: 0.3);
    }
  }
}

.dropdownContainer::-webkit-scrollbar {
  width: 16px;
}

.dropdownContainer::-webkit-scrollbar-thumb {
  background-color: #adb2b8;
  background-clip: content-box;
  border: 7px solid transparent;
}

.dropdownContainer::-webkit-scrollbar-track-piece:end {
  margin-bottom: 5px;
}

.dropdownContainer::-webkit-scrollbar-track-piece:start {
  margin-top: 5px;
}

.input {
  border: 0;
  background-color: transparent;
  padding: 0;

  font-family: "Manrope-Medium";
  font-size: 12px;
  line-height: 12px;
  color: var(--color-white);

  @media (min-width: 960px) {
    color: rgba($color: #000000, $alpha: 0.5);
  }

  &__inputField {
    font-family: "Manrope-Regular";
    font-style: normal;
    line-height: 120%;

    color: var(--color-black);
    font-size: 20px;
  }

  &__error {
    color: var(--color-errorRed);

    &::placeholder {
      color: var(--color-errorRed) !important;
    }
  }

  &__inputFieldForm {
    font-family: "Manrope-Regular";
    font-style: normal;
    line-height: 120%;

    color: var(--color-white);
    font-size: 14px;
  }
}

.input::placeholder {
  color: var(--color-black);
  opacity: 1;
}

.input__inputFieldForm::placeholder {
  color: var(--color-white);
  opacity: 1;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  opacity: 1;
}
