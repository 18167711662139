.pageWrapper {
  position: relative;
}

.blockContainer {
  z-index: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileContainer {
  position: relative;
}


.ios {
  .mobileContainer {
    //padding-bottom: 20vh;
  }
}


.mobileBlock {
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  min-height: 100%;

  &__background {
    bottom: 0;

    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 960px) {
      bottom: auto;
      top: 0;
      object-fit: cover;
    }
  }

  &_first {
    min-height: 100%;
    position: relative;
    @media (max-width: 960px) {
      min-height: 100vh;
    }
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  will-change: opacity;
}

.backgroundImage_slide {
  @extend .backgroundImage;
  opacity: 0;
  transition: opacity 800ms;

  height: 100%;
  min-height: 100vh;

  @media (min-width: 960px) {
    height: 100vh;
    min-height: unset;
  }

  &_show {
    opacity: 1;
  }
}

.backgroundContainer {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
}

.backgroundBlur {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(38.65px);
}

.tabsContainer {
  z-index: 6;
  position: fixed;
  overflow: scroll;
  width: calc(100vw - 2 * var(--desktop-horizontal-padding));

  &::-webkit-scrollbar {
    display: none;
  }

}

.bottomBar {
  position: fixed;
  bottom: 20px;
  left: 14px;
  right: 14px;
  z-index: 8;
}

.mobileTabsContainer {
  padding-left: 24px;
}

.bottomContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.tabbackbutton_white {
  background-color: #6a6a6a;
  border: none;
}

.tabbackbutton_green {
  background-color: unset;
  border: 1px solid var(--color-green);
  color: var(--color-green);
}

.tabbackbutton_icon {
  margin-right: 10px;
}

.formBackground,
.formBackground img {
  will-change: opacity;
  background-image: linear-gradient(#8a9199, #8a9199),
  linear-gradient(#8a9199, #8a9199);
  background-size: 100% 50%, 100%;
  background-repeat: no-repeat;
  object-fit: none;
  object-position: right bottom;
  @media (min-width: 960px) {
    background-size: 100% 65%, 100%;
    object-position: right center;
    object-fit: contain;
  }
}

.verticalGradient:after {
  will-change: opacity;
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 55%;
  height: 100%;
  content: "";
  font-size: larger;

  background: linear-gradient(
                  90deg,
                  #54dbc2 -3.71%,
                  rgba(84, 219, 194, 0) 78.31%
  );
}

.phoneContainer {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  right: var(--desktop-horizontal-padding);
  bottom: 48px;

  .icon {
    display: none;
  }

  .phone {
    pointer-events: auto;
    font-size: 19px;
    font-family: "Manrope-SemiBold";
    color: var(--color-white);
    text-decoration: none;
  }

  &:hover .icon {
    display: block;
    height: 100%;
    margin-right: 10px;
  }
}

.error404 {
  position: absolute;
  font-size: 450px;
  color: #54DBC2;
  font-family: Manrope-SemiBold, serif;
  left: 10%;
  top: 6%;

  @media (max-width: 960px) {
    font-size: 50vw;
    left: 4%;
    top: 27%;
    z-index: 2;
  }
}
